import React from "react";
import Button from 'react-bootstrap/Button';
import Pdfimport from './Pdfimport';
import Login from './Login/Login';
import { useAuth } from "../context/AuthContext"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../App.css"

export default function Main () {
  const { authState, setAuthState } = useAuth();
  function signOut() {
    setAuthState('INITIAL')

    localStorage.setItem("cognito-token", '')
    localStorage.setItem('cognito-idToken', '')
    localStorage.setItem('cognito-trefreshTken', '')
  }

  return (
    <>
        <div id="signOut">
            <Button variant="primary" id="upload-img" onClick={(signOut)}>
                Sign Out
            </Button>
        </div>
        {authState === 'AUTHENTICATED' ? <Pdfimport /> : <Login />}
    </>
  )
}