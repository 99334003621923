import React, { useEffect, useContext } from "react";
import './Pdfimport.css';
import '../js/refreshToken'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FiCheckCircle } from "react-icons/fi";
import { useUsername } from "../context/UsernameContext"
import { useAuth } from "../context/AuthContext"
import refreshToken from "../js/refreshToken";


//import { FiCheckCircle } from "react-icons/fi";

  const PDFJS = window.pdfjsLib;

export default function Pdfimport(props) {
  const [pdf, setPdf] = React.useState("");
  const [width, setWidth] = React.useState(0);
  const [images, setImages] = React.useState([]);
  const [height, setHeight] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pdfRendering, setPdfRendering] = React.useState("");
  const [pageRendering, setPageRendering] = React.useState("");
  const [uploadedShipment, setShipment] = React.useState("");
  const [uploadStatus, setUploadStatus] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [imgSelected, setImgSelected] = React.useState(true);
  const [imgIndex, setImgIndex] = React.useState(0);
  const { username, setUsername } = useUsername();
  const { authState, setAuthState } = useAuth();
  const [suggestions, setSuggestions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  
useEffect(() => {
    // Fetch data from API and set suggestions
    fetch('https://pod.costaec.com.au/api/shipments', {
      headers: {
        'token': localStorage.getItem("cognito-idToken")
      }
    })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Failed to fetch data');
        }
      })
      .then(data => setSuggestions(data))
      .catch(error => console.error(error));
  }, []);

  
  async function showPdf(event) {
    event.preventDefault()
    console.log(event)
    try {
      setShipment("")
      setPdfRendering(true);
      const fileInput = document.getElementById("pdfFile");
      const file = fileInput.files[0];
      console.log(file)
      if (file) {
        const reader = new FileReader();
        
        reader.onload = function(event) {
          const data = event.target.result;
          
          // Load the PDF document using PDF.js
          PDFJS.getDocument({data: data}).then(function(pdf) {
            setPdf(pdf);
          });
        }
        
      reader.readAsArrayBuffer(file);
      }
     
      setPdfRendering(false);
      document.getElementById("file-to-upload").value = "";
    } catch (error) {
      console.log(error.message);
    }
  }

  async function renderPage() {
    setPageRendering(true);
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");
    let canv = document.querySelector(".canv");

    for (let i = 1; i <= pdf.numPages; i++) {
      var page = await pdf.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport
      };
      console.log("page length", pdf.numPages);
      setWidth(viewport.width);
      setHeight(viewport.height);
      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages(imagesList);
    setPageRendering(false);
  }

  useEffect(() => {
    pdf && renderPage();
    // eslint-disable-next-line
  }, [pdf, currentPage]);

  function ImgSelect(imgId, idx) {
    const selectedImg = document.getElementsByClassName('Img-Div-Selected');
    setImgIndex(idx)
    setImgSelected(false)
    for (let i = 0; i < selectedImg.length; i++) {
      selectedImg[i].setAttribute("class", "Img-Div")
    }

    const selectedChk = document.querySelectorAll('.selected,.imgCheck')

    for (let i = 0; i < selectedChk.length; i++) {
        selectedChk[i].classList.remove("selected")
    }

    try {
      console.log(imgId)
      document.getElementById(imgId).setAttribute("class", "Img-Div-Selected")
      document.getElementById('chk' + imgId.slice(4)).classList.add("selected")
    }
    catch(error) {
      console.log(error)
    }
  }

  async function ImgUpload() {
    const imgID = document.getElementsByClassName('Img-Div-Selected');
    let shipmentId

    setUploadStatus("Loading");

    setPageRendering(true);
    
    shipmentId = document.getElementById("shipmentId").value

    if (imgID) {

      const blob = await fetch(images[imgIndex]).then(res => res.blob());
          //console.log(await blob.text())
        for (let i = 0; i < 2; i++) {
          try {

            const response = await fetch('https://pod.costaec.com.au/api/postpod', {
              method: 'POST',
              headers: {
                "Content-Type": "image/png",
                'Content-Length': blob.size,
                "shipment_id": shipmentId,
                "user": username,
                "token": localStorage.getItem("cognito-idToken"),
                'Origin': 'pods.ostaec.com.au',
                'Access-Control-Request-Method': 'POST, OPTIONS',
                'Access-Control-Request-Headers': 'token'
              },
              body: blob
            })
              let json = await response.json();

              console.log(json.message)
              if (json.message ==='Unauthorized') {
                setAuthState('INITIAL')
                setMessage(<div className="my-loader" />)
                { break }
              } else if (json.message ==='The incoming token has expired') {
                const res = await refreshToken()
                if (res ==='Not Refreshed') {
                  setAuthState('INITIAL')
                  setMessage(<div className="my-loader" />)
                  { break }
                }
              } else {
                setImages([])
                setMessage(<Alert
                  variant="success"
                  heading="Upload successful"
                  >
                  Upload successful for {shipmentId}
                </Alert>)
                document.getElementById("pdfFile").value = ''
                document.getElementById("shipmentId").value=''
              }
          } catch(e) {
            setMessage(<Alert
              variant="danger"
              >
              Upload failed for {shipmentId}
            </Alert>)
            console.log(e)
          }
        }
        setPageRendering(false);
    } else {
      setMessage(<Alert
        variant="warning"
        >
          "No image selected"
      </Alert>)
    }
    console.log(message)
  }
    

  return (
         <div className="page">
            <h1>Import POD</h1>
            <Form onSubmit={(e) => showPdf(e)} id="openPdf">
              <Row className="mb-3">
                <Col xs="auto">
                  <Form.Label>Import POD (pdf ony)</Form.Label>
                </Col>
                <Col xs="auto">
                  <Form.Control type="file" accept=".pdf" id="pdfFile"/>
                </Col>
                <Col xs="auto">
                  <Button type="submit" variant="primary" controlId="open-img" >
                      Open
                  </Button>
                </Col>
              </Row>
            </Form>
                
            <Form>
              <Form.Group as={Row} className="mb-3" >
              <Row>
                <Col xs={3}>
                  <Form.Label>Enter the Shipment ID</Form.Label>
                </Col>
                <Col xs="auto">
                  {/*Text input field that has autocomplete functionality with a list from an API call on page load */}
                  <Form.Control type="text" placeholder="Shipment ID" id="shipmentId" list="shipmentId-list" autoComplete="off" readOnly={imgSelected} onChange={(e) => setInputValue(e.target.value)}/>
                  <datalist id="shipmentId-list">
                  {suggestions
                    .filter((suggestion) =>
                      suggestion.RecordID.toLowerCase().includes(inputValue.toLowerCase())
                    )
                    .map((suggestion) => (
                      <option key={suggestion.RecordID} value={suggestion.RecordID} />
                    ))}
                </datalist>
                </Col>
                <Col xs="auto">
                  {message}
                </Col>
                </Row>
              </Form.Group>
                
                <Button variant="primary" controlId="upload-img" onClick={ImgUpload}>
                  Upload PDF
                </Button>
            </Form>
            
            
            <Form.Group id="pdf-contents" display="flex" flex-direction="column" padding-top="10px" flex-wrap="wrap" >
              {images.map((image, idx) => (
                <div className="container">
                  <Form.Label className="label">
                    <FiCheckCircle size="16em" className="imgCheck" id={"chk_" + idx} />
                    <Image 
                        src={image} 
                        id={"page_" + idx} 
                        key={idx} alt={idx} 
                        className="Img-Div" 
                        idx={idx}
                        onClick={() => {ImgSelect("page_" + idx, idx);}} />
                  </Form.Label>  
                </div>
              ))}
        </Form.Group>
        </div>
    )
}