import React, { createContext, useState, useContext } from 'react';

export const ConfirmPassContext = createContext();

export const useConfirmPass = () => {
  const context = useContext(ConfirmPassContext);
  if (!context) {
    throw new Error('useConfirmPass must be used within a ConfirmPassProvider');
  }

  return context;
};

export const ConfirmPassProvider = ({ children }) => {
  const [confirmPass, setConfirmPass] = useState('');

  return (
    <ConfirmPassContext.Provider value={{ confirmPass, setConfirmPass }}>
      {children}
    </ConfirmPassContext.Provider>
  );
};

