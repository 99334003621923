import React, {useEffect, useState} from "react";
import { CognitoIdentityProviderClient, RespondToAuthChallengeCommand, GetUserCommand } from "@aws-sdk/client-cognito-identity-provider";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../context/AuthContext"
import { useEmail } from "../../context/EmailContext"
import { usePassword } from "../../context/PasswordContext"
import { useUsername } from "../../context/UsernameContext"
import { useConfirmPass } from "../../context/ConfirmPassContext"
import Email from "./components/Email";
import Password from "./components/Password";


export default function LoginChange(props) {
  const { username, setUsername } = useUsername();
  const { password, setPassword } = usePassword();
  const { authState, setAuthState } = useAuth();
  const { email, setEmail } = useEmail();
  const { confirmPass, setConfirmPass } = useConfirmPass();
  const [passValid, setPassValid] = useState(false)

  useEffect(() => {
    if (password.length > 0 && confirmPass.length > 0) {
      if (password === confirmPass) {
        setPassValid(true)

      }
    }
  }, [password, confirmPass]);

  async function handleSubmit(event) {
    event.preventDefault()
    console.log(event)
    
    const params = {
      ChallengeName: 'NEW_PASSWORD_REQUIRED',
      ClientId: '5r3kccdtnd273lh69gtfdkhj07',
      Session: props.session,
      ChallengeResponses: {
        USERNAME: email,
        NEW_PASSWORD: password
      },
    }

    const client = new CognitoIdentityProviderClient({region: "ap-southeast-2"});
    const command = new RespondToAuthChallengeCommand(params);
    
    const res = await client.send(command);
    if (res.$metadata.httpStatusCode === 200) {
      if (res.AuthenticationResult.AccessToken) {
        setAuthState('AUTHENTICATED')
        setUsername(email)
        localStorage.setItem('cognito-accessToken', res.AuthenticationResult.AccessToken)
        localStorage.setItem('cognito-idToken', res.AuthenticationResult.IdToken)
        localStorage.setItem('cognito-refreshToken', res.AuthenticationResult.RefreshToken)
        const command = new GetUserCommand({
          AccessToken: res.AuthenticationResult.AccessToken,
        });
        
        client.send(command).then(res => {
          console.log(res);
        })
      }
    }
  }

  return (
    <div >
      <Form noValidate onSubmit={(e) => handleSubmit(e)} className="mx-auto loginForm" >
        <h2 className="lLabel h2Heading" >Password change required</h2>
        <Email disabled="true"/>
        <Password label="New Password" validate={true} passValid={passValid} setPassValid={setPassValid} />
        <Password label="Confirm Password" val={props.val}/>
        <Button variant="outline-primary" block="true" size="lg" type="submit" disabled={!passValid} className="lButton" style={{marginLeft: "4ex"}}>
          Update password
        </Button>
      </Form>
    </div>
  )
}