import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { useEmail } from "../../../context/EmailContext"

export default function Email(props) {
    const { email, setEmail } = useEmail();

    function handleChange(e) {
        setEmail(e)
    }

    function handleBlur() {
        console.log("fired")
    }

    return (
        <Form.Group size="lg" id="formEmail" className="fgroup" >
            <Row className="mb-3">
                <Col xs={3} className="col">
                    <Form.Label className="lLabel" >Email</Form.Label>
                </Col>
                <Col xs="auto">
                    <Row>
                        <Form.Control
                            controlId="emailAdd"
                            autoFocus
                            type="email"
                            name="email"
                            onChange={(e) => handleChange(e.target.value)}
                            value={email}
                            disabled={props.disabled}
                        >
                        </Form.Control>
                    </Row>
                </Col>
            </Row>
        </Form.Group>
    )
}