import { CognitoIdentityProviderClient, InitiateAuthCommand } from "@aws-sdk/client-cognito-identity-provider";

export default async function refreshToken() {
    const params = {
        ClientId: '5r3kccdtnd273lh69gtfdkhj07',
        AuthFlow: 'REFRESH_TOKEN_AUTH',
        AuthParameters: {
            REFRESH_TOKEN: localStorage.getItem('cognito-refreshToken')
          }
      }

      const client = new CognitoIdentityProviderClient({region: "ap-southeast-2"});
      const command = new InitiateAuthCommand(params);
      
      const response = await client.send(command);

      if (response.AuthenticationResult.AccessToken) {
        localStorage.setItem('cognito-accessToken', response.AuthenticationResult.AccessToken)
        localStorage.setItem('cognito-idToken', response.AuthenticationResult.IdToken)
        localStorage.setItem('cognito-refreshToken', response.AuthenticationResult.RefreshToken)
        return ('Token Refreshed')
      } else {
        return ('Not Refreshed')
      }
}